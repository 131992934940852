import ShowMore from './ShowMoreSection';

customElements.define("show-more", ShowMore);
!(function () {
    var cookieName = 'accepted_gdpr';

    window.dataLayer = window.dataLayer || [];

    var onClickTrack = function onClickTrack(e) {
        var $this = $(this),
            event = $this.data('event') || $this.attr('href');
        window.dataLayer.push({
            event: event
        });
    };

    var onBreadcrumbHover = function onBreadcrumbHover(e) {
        var $this = $(this),
            isHover = e.type === 'mouseenter';

        //console.log('onBreadcrumbHover');

        $this.css('max-width', isHover ? '' : '245px');
    };

    var onTransitionHover = function onTransitionHover(e) {
        var $this = $(this),
            $imgs = $this.find('img'),
            $footer = $this.find('footer'),
            bgColor = $this.data('bgColor'),
            hoverBgColor = $this.data('hoverBgColor'),
            isHover = e.type === 'mouseenter';

        //console.log('onTransitionHover');

        $this.toggleClass('pt-5').toggleClass('pt-3').toggleClass('color-white');

        $imgs.each(function () {
            if ($imgs.length < 2) { return; }
            $(this).toggleClass('d-none');
        });

        $footer.toggleClass('invisible');

        if (!hoverBgColor) { return; }
        $this.css('background-color', isHover ? hoverBgColor : bgColor);

    };

    var onAccordionBtnClick = function onAccordionBtnClick(e) {
        var $this = $(this),
            $parents = $this.parents('.accordion').parent().find('.accordion'),
            $fas = $this.find('.fas');

        //console.log('onAccordionBtnClick');

        $fas.addClass('fa-minus').toggleClass('fa-plus');
    };

    var onTocLnkClick = function onTocLnkClick(e) {
        e.preventDefault();

        var $this = $(this),
            href = $this.attr('href'),
            el = _(href).split('-', 2).join('-'),
            $el = $(el),
            $btn = $el.find('.btn'),
            hasCollapsed = $btn.hasClass('collapsed'),

            scrollTop = function scrollTo() {
                $(href).scrollTop();
                $('html, body').animate({
                    scrollTop: $(href).offset().top
                }, 500);
            };


        //console.log('onTocLnkClick');

        if (!hasCollapsed) { return scrollTop(); }

        $btn.trigger('click');
        scrollTop();
    };

    var onTocSelectChange = function onTocSelectChange(e) {
        e.preventDefault();

        var $this = $(this),
            href = $this.val(),
            el = _(href).split('-', 2).join('-'),
            $el = $(el),
            $btn = $el.find('.btn'),
            hasCollapsed = $btn.hasClass('collapsed'),

            scrollTop = function scrollTo() {
                $(href).scrollTop();
                $('html, body').animate({
                    scrollTop: $(href).offset().top
                }, 500);
            };


        //console.log('onTocSelectChange');

        if (!hasCollapsed) { return scrollTop(); }

        $btn.trigger('click');
        scrollTop();
    };

    var onEventsBtnClick = function onEventsBtnClick(e) {
        e.preventDefault();

        var $this = $(this),
            $footer = $this.parent(),
            $tbody = $footer.prev().find('tbody'),
            href = $this.attr('href');

        //console.log('onEventsTableClick');

        if (!href) { return; }

        $.get(href).then(function (res) {
            var $res = $(res);

            $tbody.append($res.find('tbody').html());
            $footer.html($res.find('.btn').clone().wrap('<div>').parent().html() || '');
        });
    };

    var onGridBtnClick = function onGridBtnClick(e) {
        e.preventDefault();

        var $this = $(this),
            $footer = $this.parent(),
            $grid = $footer.prev(),
            href = $this.attr('href');

        //console.log('onGridTableClick');

        if (!href) { return; }

        $.get(href).then(function (res) {
            var $res = $(res);
            $grid.append($($res[3]).clone().wrap('<div>').parent().html());
            $footer.html($res.find('.btn').clone().wrap('<div>').parent().html() || '');
        });
    };

    var onMediaGridBtnClick = function onGridBtnClick(e) {
        e.preventDefault();

        var $this = $(this),
            $footer = $this.parent(),
            $grid = $footer.is('[data-mobile-carousel]') ? $footer.prev().prev() : $footer.prev(),
            $gridCarousel = $footer.is('[data-mobile-carousel]') ? $footer.prev().children('.carousel-inner') : [],
            href = $this.attr('href');

        //console.log('onGridTableClick');

        if (!href) { return; }

        $.get(href).then(function (res) {
            var $res = $(res);
            $grid.append($res.find('.row').clone().wrap('<div>').parent().html());

            if ($gridCarousel.length) {
                $gridCarousel.append($res.find('.carousel-inner').clone().html());
            }
            $footer.html($res.find('.btn').clone().wrap('<div>').parent().html() || '');
        });
    };

    var onFormSubmit = function onFormSubmit(e) {
        e.preventDefault();

        var $this = $(this),
            $gdpr = $('.js-gdpr'),
            expires = $this.data('expires') || 60,
            action = $this.attr('action'),
            method = $this.attr('method'),
            data = {
                message: 'accepted'
            };

        //console.log('onFormSubmit');

        $gdpr.addClass('d-none');
        Cookies.set(cookieName, true, { expires: expires });

        window.dataLayer.push({
            event: e
        });

        $.ajax({
            method: method,
            url: action,
            data: data
        }).then(function (res) {
            //console.log(res);
        }).done(function (err) {
            //console.log(err);
        });
    };

    var initNavbar = function initNavbar() {
        $('#idsaMemberActions').on('show.bs.collapse', function () {
            if ($('#navbarSupportedContent').hasClass('show')) {
                $('.idsaHeaderHamburger > button').trigger('click');
            }
        });
    };

    var initGTM = function initGTM() {
        var gtmIds = $('body').data('gtmIds');

        //console.log('initGTM');

        if (!gtmIds) { return; };

        function addGtmId(w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        }

        for (i = 0; i < gtmIds.length; i++) {
            addGtmId(window, document, 'script', 'dataLayer', gtmIds[i]);
        }
    };

    /*
    var initGA = function initGA() {
        var gaId = $('body').data('gaId');

        //console.log('initGA');

        if (!gaId) { return; }

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                                 m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', gaId, 'auto');
        ga('send', 'pageview');
    };
    */

    var initGdpr = function initGdpr() {
        var cookie = Cookies.get(cookieName),
            $gdpr = $('.js-gdpr');

        //console.log('initGdpr');

        if (cookie) { return; }

        $gdpr.on('submit', 'form', onFormSubmit);
        $gdpr.removeClass('d-none');
    };

    var initCarousels = function initCarousels() {
        var touchStartX = null;

        //console.log('initCarousels');

        $('.carousel').each(function () {
            var $carousel = $(this);
            $(this).on('touchstart', function (event) {
                var e = event.originalEvent;
                if (e.touches.length == 1) {
                    var touch = e.touches[0];
                    touchStartX = touch.pageX;
                }
            }).on('touchmove', function (event) {
                var e = event.originalEvent;
                if (touchStartX != null) {
                    var touchCurrentX = e.changedTouches[0].pageX;
                    if ((touchCurrentX - touchStartX) > 60) {
                        touchStartX = null;
                        $carousel.carousel('prev');
                    } else if ((touchStartX - touchCurrentX) > 60) {
                        touchStartX = null;
                        $carousel.carousel('next');
                    }
                }
            }).on('touchend', function () {
                touchStartX = null;
            });
        });
    };

    var initGuidelines = function initGuidelines() {
        if($(".legacy-guideline-detail-page").length > 0) return;
        var $toc = $('.js-toc'),
            $tocMobile = $('.js-toc-select'),
            cls = '.js-transform-accordion-header',
            $headers = $(cls),
            secs = [],
            tmpl = '',

            template = function template(secs, isMob, cls) {
                if (isMob) {
                    return '<select class="custom-select d-block d-lg-none my-2 js-toc-select">' +

                        _.reduce(secs, function (memo, item) {
                            memo += '<option value="' + item.href + '">' + item.title + '</option>';
                            //Comment out children
                            //                        if (item.secs) {
                            //                            memo += _.reduce(item.secs, function(memo, item) {
                            //                                return memo += '<option value="' + item.href + '">' + item.title + '</option>';
                            //                            }, '');
                            //                        }
                            return memo;
                        }, '') +

                        '</select>';
                }

                return '<ul class="list-unstyled mt-2 mb-2 d-none d-lg-block js-toc ' + (cls ? cls : '') + '">' +

                    _.reduce(secs, function (memo, item) {
                        memo += '<li class="mt-2 mb-3 ' + item.ml + ' lh-11"><a id="' + item.id + '" class="lnk" href="' + item.href + '" onclick= ' + item.onclick + ' data-ga="' + item.parent + '">' +
                            item.title + '</a>';
                        // uncomment for subsections
                        if (item.secs) {
                            memo += template(item.secs, isMob, 'ml-4');
                        }
                        return memo + '</li>';
                    }, '') +

                    '</ul>';
            };

        //console.log('initGuidelines');

        $headers.each(function (idx) {
            var $this = $(this),
                $els = $this.nextUntil(cls + ', .js-toc-to-top, .js-transform-accordion-body-end'),
                hd = $this.text(),
                hdref = $this.text().replace(/ /g, '').replace('(', '').replace(')', ''),
                bd = '',
                style = $this.attr('style'),
                isCollapsed = $this.is('.js-transform-accordion-collapsed'),
                isCollapsedMobile = $this.is('.js-transform-accordion-collapsed-mobile') && _.isMobile(),
                sec;

            sec = {
                href: '#toc-' + idx,
                title: hd,
                id: hdref,
                parent: hd,
                secs: []
            };

            $els.each(function (cnt) {
                var $this = $(this),
                    isHeading = $this.is('h1, h2:not(.hide-in-nav), h3:not(.hide-in-nav), h4:not(.hide-in-nav)');

                if (isHeading) {
                    $this.attr('id', 'toc-' + idx + '-' + cnt);
                    sec.secs.push({
                        href: '#toc-' + idx + '-' + cnt,
                        title: $this.text(),
                        id: hdref,
                        parent: hd,
                        ml: $this.is('h4') ? 'ml-2' : ''
                    });
                }

                bd += $this.clone().wrap('<div>').parent().html();
            });

            secs.push(sec);

            cardCloseButton = '<a href="#collapse-' + idx + '" class="' + (isCollapsed || isCollapsedMobile ? 'collapsed' : '') + ' card-gototop-trigger" data-toggle="collapse" data-target="#collapse-' + idx + '" aria-expanded="' + (!(isCollapsed || isCollapsedMobile)) + '" aria-controls="collapse-' + idx + '"  data-gototop="#accordion-' + idx + '">' + '<i class="fas fa-minus"></i>' + '</a>';

            cardGoToTopButton = '<div class="card-gototop-trigger" data-gototop="#accordion-' + idx + '">' + '<i class="fas fa-arrow-alt-circle-up"></i>' + '</div>';

            tmpl = '<div class="accordion border-bottom mb-4 js-accordion" id="accordion-' + idx + '">' +
                '<div class="card"><div class="card-header rounded-0" id="introduction">' +
                '<h2 class="mb-0" id="toc-' + idx + '" style="' + style + '">' +
                '<button id="' + hdref + '"class="btn btn-block btn-link lnk-3 h3 mb-0 text-left text-wrap js-gtm-practice-guidelines-accordion ' + (isCollapsed || isCollapsedMobile ? 'collapsed' : '') +
                '" type="button" data-toggle="collapse" data-target="#collapse-' + idx + '" aria-expanded="' + (!(isCollapsed || isCollapsedMobile)) +
                '" aria-controls="collapse-' + idx + '">' +
                hd + '<i class="fas fa-' + ((isCollapsed || isCollapsedMobile) ? 'plus' : 'minus') + ' mt-2 float-right"></i>' +
                '</button></h2></div>' +
                '<div id="collapse-' + idx + '" class="collapse ' + (isCollapsed || isCollapsedMobile ? '' : 'show') + '" aria-labelledby="introduction" data-parent="#accordion-' + idx + '">' +
                '<div class="card-body">' + bd + '<div class="card-gototop">' + cardGoToTopButton + '</div>' + '<div class="card-close">' + cardCloseButton + '</div>' + '</div>' +
                '</div></div></div>';

            $this.replaceWith(tmpl);
            $els.remove();
        });

        tmpl = template(secs);
        $toc.replaceWith(tmpl);
        tmpl = template(secs, true);
        $tocMobile.replaceWith(tmpl);
        $('.js-toc').on('click', 'a', onTocLnkClick);
        $(document).on('change', '.js-toc-select', onTocSelectChange);

        if (window.location.pathname.indexOf('#toc-')) {
            var hashTocID = window.location.hash;
            $('a[href="' + hashTocID + '"]', $('ul.js-toc')).trigger('click');
        }

        if (typeof StickySidebar == 'function') {
            if($('.idsaPracticeGuidelinePage_Nav').length == 0) return;
            var stickyLeftNav = new StickySidebar('.idsaPracticeGuidelinePage_Nav > div', {
                topSpacing: 30,
                bottomSpacing: 100,
                containerSelector: '.idsaPracticeGuidelinePage_Nav'
            });
            $('.accordion').on('show.bs.collapse', function () {
                stickyLeftNav.updateSticky();
            });
            $('.accordion').on('show.bs.collapse', function () {
                stickyLeftNav.updateSticky();
            });
            $('.accordion').on('hide.bs.collapse', function () {
                stickyLeftNav.updateSticky();
            });
            $('.accordion').on('hidden.bs.collapse', function () {
                stickyLeftNav.updateSticky();
            });
        }

        //if there's a hash and it isn't the bravo hash
        if (window.location.hash && !window.location.hash.includes('/')) {
            let $accordion;
            try{
                $accordion = $(window.location.hash)
            }catch(err){
                $accordion	 = $($.escapeSelector(window.location.hash));
            }

            if ($accordion.length) {
                var accordion = $accordion[0]

                accordion.click()
                accordion.scrollIntoView({ behavior: "smooth" })
            }
        }

        $('button[aria-expanded="false"][id]').on('click', (evt) => {
            window.location.hash = evt.target.getAttribute('id')
        })
    };

    var initAccordions = function initAccordions() {
        var $accordions = $('.js-accordion');

        //console.log('initAccordions');

        $accordions.each(function () {
            var $this = $(this),
                $btn = $this.find('.card-header .btn'),
                isCollapsed = $this.is('.js-transform-accordion-collapsed'),
                isCollapsedMobile = $this.is('.js-transform-accordion-collapsed-mobile') && _.isMobile();

            // console.log(isCollapsed || isCollapsedMobile);

            if (!(isCollapsed || isCollapsedMobile)) return;

            $btn.trigger('click');
            //$fas.addClass('fa-plus');
        });
    };

    const initAnchors = () => {
        const anchorsWrapper = document.querySelector('nav.page-anchors div.page-anchors-content');
        if (!anchorsWrapper)
            return;

        const anchorsList = anchorsWrapper.querySelector('ul.page-anchors-content__list');
        if (!anchorsList)
            return;

        const intersectOptions = {
            firstItem: anchorsList.querySelector('li'),
            lastItem: anchorsList.querySelector('li:last-of-type'),
            btnPrev: anchorsWrapper.querySelector('button.page-anchors-content__btn-prev'),
            btnNext: anchorsWrapper.querySelector('button.page-anchors-content__btn-next'),
            classes: {
                overflowStart: 'has-overflow-start',
                overflowEnd: 'has-overflow-end'
            },
            settings: {
                root: anchorsList,
                rootMargin: '1px',
                threshold: 1.0
            }
        };

        intersectOptions.btnPrev.addEventListener('click', e => {
            e.preventDefault();
            anchorsList.scrollBy({
                left: -anchorsList.offsetWidth,
                behavior: "smooth"
            });
        });

    };
    const initToC = () => {
        const updateProgress = () => {
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            setProgressVariable((scrollTop / height) * 100);
        };

        const getChapterMap = (tocWrapper, tocContent) => {
            const chapterMarkers = [...tocWrapper.querySelectorAll('li.toc-chapter')];
            const repetitionMap = new Map();
            const chapterMap = chapterMarkers.map(chapterMarker => {
                // this has a backstory.
                // can't use native querySelector because:
                // 1. IDs may repeat(They should never. But they do, for some existing data)
                // 2. querySelector only returns the first element with the ID
                // Note: jQuery's $() function ALSO returns the first element with the ID
                // so we pick by attribute ID instead
                const chapterHeadings = $(`[id^=${chapterMarker.dataset.chapterId}]:not([id$='-duped-id-index-[0-9]'])`);
                if(chapterHeadings.length === 1) return [chapterHeadings[0], chapterMarker];
                const oldID = chapterMarker.dataset.chapterId;

                const repetitionIndex = repetitionMap.get(oldID);
                const newIndex = repetitionIndex === undefined ? 0 : repetitionIndex + 1;
                repetitionMap.set(oldID, newIndex);

                const newID = `${oldID}-duped-id-index-${newIndex}`;
                chapterMarker.dataset.chapterId = newID;
                chapterMarker.querySelector('a').href = `#${newID}`;
                chapterHeadings[newIndex].id = newID;
                return [chapterHeadings[newIndex], chapterMarker];
            });
            return new Map(chapterMap);
        }

        const setProgressVariable = (percentage) => {
            document.documentElement.style.setProperty('--toc-gradient-percent', `${percentage}%`);
        };

        const header = document.querySelector('header.idsa-header');
        const tocWrapper = document.querySelector('.toc-wrapper');
        const tocTable = document.querySelector('.toc-table');
        const tocScrollable = document.querySelector('.toc-table__chapters');
        if (!tocWrapper)
            return;

        const tocMobileToggle = document.querySelector('.toc-mobile-cntrl button.toc-mobile-cntrl__btn');
        if (tocMobileToggle) {
            tocMobileToggle.addEventListener('click', () => {
                tocWrapper.classList.add("is-open");
                if (header) header.style.zIndex = 1;
            });
        }

        const tocContent = document.querySelector('.toc-content');
        updateProgress(tocContent);
        document.addEventListener('scroll', () => updateProgress(tocContent));

        const headerOffset = document.querySelector('.idsa-header-main').offsetHeight;
        const chapterMap = getChapterMap(tocWrapper, tocContent);

        const tocCloseBtn = tocWrapper.querySelector('button.toc-btn-close');
        tocCloseBtn.addEventListener('click', () => {
            tocWrapper.classList.remove("is-open");
            if (header) header.style.zIndex = '';
        });
        tocWrapper.addEventListener('click', (e) => {
            // if click happened at toc-table or inside it
            if (tocTable.contains(e.target)) {
                return;
            }
            tocWrapper.classList.remove("is-open");
            if (header) header.style.zIndex = '';
        });
        
        const activeObserver = new IntersectionObserver(() => {
            const targetHeading = [...chapterMap.keys()].find(x => {
                return x.getBoundingClientRect().top > headerOffset;
            });

            for (const marker of chapterMap.values()) {
                marker.classList.remove('active');
            }

            if (targetHeading) {
                const targetMarker = chapterMap.get(targetHeading);
                targetMarker.classList.add('active');
                tocScrollable.scrollTop = targetMarker.offsetTop - tocScrollable.offsetTop;
            }
        }, { threshold: 1.0, rootMargin: `-${headerOffset}px 0px 0px 0px` });

        for (const heading of chapterMap.keys()) {
            activeObserver.observe(heading);
        }
    };

    const initCopyLinks = () => {
        const copyLinkBtns = document.querySelectorAll('button.copy-link');
        for (const copyLinkBtn of copyLinkBtns) {
            copyLinkBtn.addEventListener('click', () => {
                navigator.clipboard.writeText(window.location.href);
            });
        }
    };

    var init = function () {
        if(window._) {
            _.mixin({
                isMobile: function () { return (/iphone|ipod|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase()); },
                isLg: function () { return $(window).width() > 991; },
                isSm: function () { return $(window).width() < 576; },
                isXl: function () { return $(window).width() >= 1200; },
            });
        }


        initNavbar();

        initGTM();

        //initGA();

        initGdpr();

        initCarousels();

        initAnchors();

        initToC();

        initCopyLinks();

        $(document).on('click', 'a', onClickTrack);
        /*$('.js-breadcrumb').on('mouseenter mouseleave', '.breadcrumb-item', onBreadcrumbHover);*/

        $('.js-transition').on('mouseenter mouseleave', onTransitionHover);

        initAccordions();
        initGuidelines();

        //$('.js-accordion').on('click', '.btn', onAccordionBtnClick);
        $('.js-toc-to-top').on('click', 'a', function () { $('html, body').animate({ scrollTop: 0 }, 500); });

        $('.card-gototop .card-gototop-trigger').on('click',
            function () {
                $('html, body').animate({ scrollTop: 0 }, 150);
            }
        );

        $('.card-close a').on('click',
            function () {
                $('html, body').animate({ scrollTop: $($(this).data('gototop')).offset().top - 24 }, 0);
            }
        );

        $(document).on('click', '.js-events-btn', onEventsBtnClick);
        $(document).on('click', '.js-grid-btn', onGridBtnClick);
        $(document).on('click', '.js-media-grid-btn', onMediaGridBtnClick);
        $('.js-match-height').matchHeight();
        $('.js-datetimepicker').datetimepicker({ format: 'L' });
        $('#idsasearcHTrgr').on('click', function () {
            $('.idsaSuperHeaderSearchDsktp').fadeToggle(300);
        });
        $('#idsasearcHTrgrCls').on('click', function () {
            $('.idsaSuperHeaderSearchDsktp').fadeToggle(300);
        });

        $('#idsaSuperHeaderSearchOverlay').on('show.bs.collapse', function () {
            $(document).one('keydown.idsaSuperHeaderSearchTrigger', function (e) {
                if (e.key == "Escape" || e.keyCode === 27) {
                    //console.log('esc');
                    $('#idsaSuperHeaderSearchOverlay').collapse('hide');
                }
            });
        });
        $('#idsaSuperHeaderSearchOverlay').on('hidden.bs.collapse', function () {
            $(document).off('keydown.idsaSuperHeaderSearchTrigger');
        });

        $(".rtln_carousel").owlCarousel({
            margin: 0,
            autoWidth: false,
            nav: true,
            navText: ['<span class="fas fa-caret-left"></span>', '<span class="fas fa-caret-right"></span>'],
            beforeInit: function (elem) {
                random(elem);
            },
            autoplay: false,
            //autoplayTimeout: 8000,
            //autoplayHoverPause: true,
            dots: false,
            responsiveClass: true,
            loop: true,
            rewind: true,
            navSpeed: 1000,
            responsive: {
                0: { items: 1, slideBy: 1 }
            }

        });

        var partialLabelDetails = {
            labelColumns: [0, 1],
            labelFunction: function (firstColumn, secondColumn) {
                return firstColumn.innerText + ' ' + secondColumn.innerText;
            }
        };


        $('.deque-table-responsive').each(function (table) {
            deque.tables.makeTableResponsive(this, partialLabelDetails, 992);
        });
        $('article a[href^="https://"],article a[href^="http://"]').not("a[href*='" + window.location.host + "'], a.link-block, a.list-group-item, a.twitter-timeline, a.IconBlock, a.idsaHoverBlock,:has(i.fas),:has(img), a.has-icon ")
            .attr('target', '_blank').append('<i class="fas fa-external-link-alt fa-xs m-1"></i>');
        $('a.idsaHoverBlock[href^="https://"],a.idsaHoverBlock[href^="http://"]').not("a[href*='" + window.location.host + "']").append('<span class="link-icon"><i class="icon icon-external-link"></i><span class="link-icon-bg"></span></span>').find('i.fas').remove();
        $('a.idsaHoverBlock').not(":has(.link-icon)").append('<span class="link-icon"><i class="icon icon-internal-link"></i><span class="link-icon-bg"></span></span>');

        $('.onload-modal.modal,.modal-backdrop.onload-modal').addClass('show');
        $('.onload-modal.modal').show();
        $('.onload-modal.modal .close, .fade.onload-modal.show').click(function () {
            $('.onload-modal').hide();
            $('.modal-backdrop.onload-modal').hide();
        });
        $('.idsa-table tr:not(:first-child) td').wrapInner("<div class='td-content'></div>");
        $('.idsa-table').each(function () {
            $(this).find('tr:nth-child(n+2) td:nth-child(1)').attr('data-label', $(this).find('tr:first-child td:nth-child(1)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(2)').attr('data-label', $(this).find('tr:first-child td:nth-child(2)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(3)').attr('data-label', $(this).find('tr:first-child td:nth-child(3)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(4)').attr('data-label', $(this).find('tr:first-child td:nth-child(4)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(5)').attr('data-label', $(this).find('tr:first-child td:nth-child(5)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(6)').attr('data-label', $(this).find('tr:first-child td:nth-child(6)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(7)').attr('data-label', $(this).find('tr:first-child td:nth-child(7)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(8)').attr('data-label', $(this).find('tr:first-child td:nth-child(8)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(9)').attr('data-label', $(this).find('tr:first-child td:nth-child(9)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(10)').attr('data-label', $(this).find('tr:first-child td:nth-child(10)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(11)').attr('data-label', $(this).find('tr:first-child td:nth-child(11)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(12)').attr('data-label', $(this).find('tr:first-child td:nth-child(12)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(13)').attr('data-label', $(this).find('tr:first-child td:nth-child(13)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(14)').attr('data-label', $(this).find('tr:first-child td:nth-child(14)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(15)').attr('data-label', $(this).find('tr:first-child td:nth-child(15)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(16)').attr('data-label', $(this).find('tr:first-child td:nth-child(16)').text());
            $(this).find('tr:nth-child(n+2) td:nth-child(17)').attr('data-label', $(this).find('tr:first-child td:nth-child(17)').text());
        });

        //modals on img click to enlarge for PG pages
        $('.idsaPracticeGuidelinePage .card-body', document).on('click', 'a', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            evt.stopImmediatePropagation();

            var $parentLink = $(this);
            var downloadLink = $parentLink.attr('href');
            var target = $parentLink.attr('target') ?? '_self';
            var $child = $($parentLink.children()).first();
            var linkHref = $(this).attr('href');

            //if the link isn't an image, fire original event
            if (!$child || !$child.is('img')) {
                window.open(downloadLink, target);

                return false;
            }

            var imgUrl = $child.attr('src');
            var imgAlt = $child.attr('alt');

            var $modal = $('#pgImgModal');

            $('.js-modal-img', $modal).html('<img class="img-fluid" src="' + imgUrl + '" alt="' + imgAlt + '" />');
            if (linkHref !== "") {
                $('.js-modal-btn', $modal).html('<a class="btn btn-secondary p-2 rounded-0 m-3" target="_blank" href="' + downloadLink + '">Download</a>');
            }


            $modal.modal('show');
        });

        //
        // idsociety.org Header start

        var _headerNav = $('.idsa-header-main').get(0);
        var _header = _headerNav; // $('header').get(0);
        var _headerParent = $('header').get(0); //$('header').parent().get(0);
        var _headerUtility = $('.idsa-header-utility').get(0);
        //var _searchPageFiltersPanel = $('.search-results-filters-panel').get(0);
        var _headerHeight = (_header) ? $(_header).height() : 0;
        var _utilityHeight = (_headerUtility) ? $(_headerUtility).height() : 0;
        var _headerTriggerOffset = $(_headerParent).offset().top + _utilityHeight;
        if (_header && _headerParent) {
            $(window).on('scroll', function () {
                CalculateFixedHeader();
            });
            $(window).on('resize', function () {
                _headerHeight = $(_header).height();
                _utilityHeight = $(_headerUtility).height();
                _headerTriggerOffset = $(_headerParent).offset().top + _utilityHeight;
                CalculateFixedHeader();
            });
            CalculateFixedHeader();
        }
        function CalculateFixedHeader() {
            if ($(window).scrollTop() > _headerTriggerOffset) {
                $(_header).addClass('header-fixed');
                $(_headerParent).addClass('header-fixed');
                $(_headerParent).css({ 'padding-bottom': _headerHeight + 'px' });
            } else {
                $(_headerParent).css({ 'padding-bottom': '0px' });
                $(_header).removeClass('header-fixed');
                $(_headerParent).removeClass('header-fixed');
                //closeNav();
            }
        }

        //$('.idsa-header-main-nav-trigger').on('click', function () {
        //    var _this = $(this);
        //    var _target = $(this).data('target');
        //});

        $('#idsa-header-main-nav').on('show.bs.collapse', function () {
            $('html, body').addClass('nav-expanded');
        }).on('hide.bs.collapse', function () {
            $('html, body').removeClass('nav-expanded');
        });

        //var _elTriggerOnEnter = $('.idsa-header-main-search, .idsa-header-main-nav-trigger');
        //$(_elTriggerOnEnter).on('focus', function () {
        //    var _this = $(this);
        //    $(document).on('keyup.TriggerOnEnter', function (e) {
        //        if (e.key == "Enter" || e.keyCode === 13 || e.key == " " || e.keyCode === 32) {
        //            $(_this).trigger('click');
        //        }
        //    });
        //}).on('focusout', function () {
        //    $(document).off('keyup.TriggerOnEnter');
        //});

        $('#idsa-header-main-nav').on('show.bs.collapse', function () {
            var _this = $(this);
            $(document).on('keydown.EscNavTriggered', function (e) {
                if (e.key == "Escape" || e.keyCode === 27) {
                    $(_this).collapse('hide');
                }
            });
        }).on('hidden.bs.collapse', function () {
            $(document).off('keydown.EscNavTriggered');
        });

        $('#idsa-header-search').on('show.bs.collapse', function () {
            var _this = $(this);
            $(document).on('keydown.EscSearchTriggered', function (e) {
                if (e.key == "Escape" || e.keyCode === 27) {
                    $(_this).collapse('hide');
                }
            });
        }).on('hidden.bs.collapse', function () {
            $(document).off('keydown.EscSearchTriggered');
        });

        $('#idsa-header-main-nav').on('show.bs.collapse', function () {
            $('#idsa-header-search').collapse('hide');
        });
        $('#idsa-header-search').on('show.bs.collapse', function () {
            $('#idsa-header-main-nav').collapse('hide');
        });

        $(window).on('resize', function () {
            $('#idsa-header-main-nav').collapse('hide');
            $('#idsa-header-search').collapse('hide');
        });


        // mobile nav start

        $('ul.lvl-2.collapse').on('show.bs.collapse shown.bs.collapse hide.bs.collapse hidden.bs.collapse', function (e) {
            e.stopPropagation();
        });

        $('.main-nav-trigger-open').on('click', function (e) {
            if ($(this).hasClass('ddExpanded')) {
                $(this).removeClass('ddExpanded');
                $(this).parent().removeClass('ddExpanded');
                $(this).parents('.lvl-0.hasChildren').removeClass('ddExpanded');
                $('#idsa-header-main-nav').removeClass('ddExpanded');
            }
            else {
                $(this).addClass('ddExpanded');
                $(this).parent().addClass('ddExpanded');
                $(this).parents('.lvl-0.hasChildren').addClass('ddExpanded');
                $('#idsa-header-main-nav').addClass('ddExpanded');
            }
        });

        $('.main-nav-trigger-close').on('click', function (e) {
            $('.ddExpanded').removeClass('ddExpanded');
        });
        $(window).on('resize', function () {
            $('.ddExpanded').removeClass('ddExpanded');
        });

        // mobile nav end
        const toggleAccountMenu = ({
            accountButtonToggle,
            accountMenuContainer,
            targetValue = undefined,
            isMobile = false,
        }) => {
            const canonNewValue = typeof targetValue === "boolean" ? targetValue : $(accountButtonToggle).attr("aria-expanded") === "false";
            $(accountButtonToggle).attr("aria-expanded", canonNewValue);
            $(accountMenuContainer).attr("aria-hidden", !canonNewValue);
            if(canonNewValue)
            {
                $(accountMenuContainer).removeAttr("inert");
            }
            else
            {
                $(accountMenuContainer).attr("inert", "");
            }

            if(isMobile){
                $(accountButtonToggle).find("i").css("display", canonNewValue ? "none" : "");
                $(accountButtonToggle).find("span").css("margin-left", canonNewValue ? "1rem" : "");
                $(accountButtonToggle).css("padding-top", canonNewValue ? "0" : "");
                $(accountButtonToggle).css("align-items", canonNewValue ? "center" : "");
                $(accountButtonToggle).css("justify-content", canonNewValue ? "flex-start" : "");
                $(accountButtonToggle).parent().css("border-top", canonNewValue ? "0" : "");
                $(accountButtonToggle).parent().css("margin-top", canonNewValue ? "0" : "");
                $(".idsa-header-utility-links-nav").css("border-top", canonNewValue ? "0" : "");
                $(".idsa-header-utility-links-nav").css("margin-top", canonNewValue ? "0" : "");
                const otherListItems = $(accountButtonToggle).parent().siblings();
                otherListItems.each(function(){
                    $(this).css("display", canonNewValue ? "none" : "");
                });
                $(".idsa-header-main-nav-head").css("display", canonNewValue ? "none" : "");
                $("#idsa-main-nav").css("display", canonNewValue ? "none" : "");
            }
        }

        for(const accountButtonToggle of $(".member-dropdown-toggle")) {
            const accountMenuContainer = $(accountButtonToggle).siblings(".member-dropdown-menu-container").first();
            const isMobile = accountButtonToggle.closest(".idsa-header-main") != null;

            $(accountButtonToggle).on("click", (e) => {
                e.preventDefault();
                toggleAccountMenu({
                    accountButtonToggle,
                    accountMenuContainer,
                    isMobile,
                });
            });
    
            $(accountButtonToggle).on("keydown", (e) => {
                if(e.key === "Enter" || e.key === " ")
                {
                    e.preventDefault();
                    toggleAccountMenu({
                        accountButtonToggle,
                        accountMenuContainer,
                        isMobile,
                    });
                }
            });
    
            // on click outside
            $(document).on("click", (e) => {
                if(!$(accountButtonToggle).is(e.target) && $(accountButtonToggle).has(e.target).length === 0 && !$(accountMenuContainer).is(e.target) && $(accountMenuContainer).has(e.target).length === 0)
                {
                    toggleAccountMenu({
                        accountButtonToggle,
                        accountMenuContainer,
                        targetValue: false,
                        isMobile,
                    });
                }
            });
        }

        // idsociety.org Header end
        //

        // idsociety.org start
        $('.banner-block.banner-block-expandsfullwidth').each(function () {
            var _this = $(this);
            var bannerObserver = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (entry['isIntersecting'] === true) {
                            if (entry['intersectionRatio'] >= 0 && entry['intersectionRatio'] <= 0.45) {
                                //if (entry['boundingClientRect'].top > window.scrollY) {
                                $(_this).removeClass('isExpanded');
                                //}
                            }
                            else {
                                $(_this).addClass('isExpanded');
                            }
                        }
                        else {
                            //if (entry['boundingClientRect'].top > window.scrollY) {
                            $(_this).removeClass('isExpanded');
                            //}
                        }
                    })
                },
                { threshold: [0, 0.45, 1] });

            bannerObserver.observe($(_this).get(0));
        });

        $('.spotlight-block').each(function () {
            var _this = $(this);
            $('.spotlight-tab-pill', $(_this)).on('click', function (e) {
                var _pill = $(this);
                var targetguid = $(_pill).data('targetguid');
                $(_pill).addClass('active').attr({ 'aria-expanded': 'true', 'aria-selected': 'true' });
                $(_pill).siblings().removeClass('active').attr({ 'aria-expanded': 'false', 'aria-selected': 'false' });
                $('#spotlight-image-' + targetguid, $(_this)).addClass('active');
                $('#spotlight-image-' + targetguid, $(_this)).siblings().removeClass('active');
                $('#spotlight-tab-item-' + targetguid, $(_this)).addClass('active');
                $('#spotlight-tab-item-' + targetguid, $(_this)).siblings().removeClass('active');
            }).on('keyup.hitEnter', function (event) {
                var _pill = $(this);
                if (event.which == 13) {
                    $(_pill).trigger('click');
                }
            });
        });

        $('.multicolumn-content-col.sticky-header .mediacard-block').each(function () {
            var _this = $(this);
            var mediacardObserver = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (entry['isIntersecting'] === true) {
                            if (entry['intersectionRatio'] >= 0 && entry['intersectionRatio'] <= 0.5) {
                                //if (entry['boundingClientRect'].top > window.scrollY) {
                                $(_this).removeClass('isFocused');
                                //}
                            }
                            else {
                                $(_this).addClass('isFocused');
                            }
                        }
                        else {
                            //if (entry['boundingClientRect'].top > window.scrollY) {
                            $(_this).removeClass('isFocused');
                            //}
                        }
                    })
                },
                { threshold: [0, 0.5, 1], rootMargin: '0px 0px -25% 0px' });

            mediacardObserver.observe($(_this).get(0));
        });

        $(document).on("click", "a[href^='#']", function (e) {
            const anchor = $(this);
            const targetElement = $(anchor.attr('href'));
            if(!targetElement.length) return;
            const targetElementOffsetTop = targetElement.offset().top;

            const elementScrollMarginTop = targetElement.css('scroll-margin-top');
            const elementScrollMarginBlockStart = targetElement.css('scroll-margin-block-start');

            const canonScrollMarginTop = elementScrollMarginTop === "0px" ? 0 : Number.parseInt(elementScrollMarginTop);
            const canonScrollMarginBlockStart = elementScrollMarginBlockStart === "0px" ? 0 : Number.parseInt(elementScrollMarginBlockStart);

            const canonScrollMargin = canonScrollMarginBlockStart !== 0 ? canonScrollMarginBlockStart : canonScrollMarginTop;

            const computedHeaderHeight = $("header").height();
            e.preventDefault();
            // stop
            $('html, body').stop();
            $('html, body').animate({
                scrollTop: targetElementOffsetTop - canonScrollMargin - computedHeaderHeight
            }, 500);
        });
        
        $(document).on("click", ".legacy-guideline-detail-page #CitationButton", function (e) {
            e.preventDefault();
            const _this = $(this);
            const _target = $($(_this).data('target'));
            const willTargetExpand = !_target.hasClass('show');
            let $placeholder = null;
            let $backdrop = null;
            // Manage the modal backdrop
            if (willTargetExpand) {
                // Create and append the backdrop
                $backdrop = $('<div>', {
                    class: 'modal-backdrop',
                    css: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 1040
                    }
                });
                
                $('body').append($backdrop);
        
                // Temporarily move the target after the backdrop
                const $originalParent = _target.parent();
                const $placeholder = $('<div>').insertBefore(_target); // Placeholder to remember the original position
                $('body').append(_target);
        
                // Close the modal and remove the backdrop when the backdrop is clicked
                $backdrop.on('click', function () {
                    _target.removeClass('show');
                    _target.css('display', '');
                    _target.css("position", "");
                    _target.css("top", "");
                    _target.css("left", "");
                    _target.css("width", "");
                    _target.css("height", "");
                    $placeholder.replaceWith(_target); // Return to the original position
                    $(this).remove();
                });

                $(window).on("keydown", function (e) {
                    // Close the modal and remove the backdrop when the ESC key is pressed
                    if (e.key === "Escape" || e.keyCode === 27) {
                        _target.removeClass('show');
                        _target.css('display', '');
                        _target.css("position", "");
                        _target.css("top", "");
                        _target.css("left", "");
                        _target.css("width", "");
                        _target.css("height", "");
                        $placeholder.replaceWith(_target); // Return to the original position
                        $backdrop.remove();
                    }
                });
        
                // Show the target
                _target.addClass('show');
                _target.css('display', 'block');
                _target.css("position", "fixed");
                _target.css("top", "50%");
                _target.css("left", "50%");
                _target.css("transform", "translate(-50%, -50%)");
                _target.css("pointer-events", "none");
                _target.css("min-width", "35dvh");
                _target.css("height", "70dvh");

            } else {
                // Remove the backdrop and return the target to its original position
                $backdrop = $('.modal-backdrop');
                $placeholder = _target.prev('.placeholder');
        
                _target.removeClass('show');
                _target.css('display', '');
                _target.css("position", "");
                _target.css("top", "");
                _target.css("left", "");
                _target.css("transform", "");
                _target.css("min-width", "");
                _target.css("height", "");
        
                if ($placeholder.length) {
                    $placeholder.replaceWith(_target); // Return to the original position
                }
                $backdrop.remove();
            }
            const modalCloseBtn = _target.find('.close');
            modalCloseBtn.on('click', function () {
                _target.removeClass('show');
                _target.css('display', '');
                _target.css("position", "");
                _target.css("top", "");
                _target.css("left", "");
                _target.css("width", "");
                _target.css("height", "");
                if($placeholder){
                    $placeholder.replaceWith(_target); // Return to the original position
                }
                if($backdrop){
                    $backdrop.remove();
                }
            });
        });

        const getVisibleHeight = (elSelector) => {
            const $el = $(elSelector);
            if($el.length === 0) return 0;
            if($el.length > 1) throw new Error("getVisibleHeight: More than one element found for the selector");
            // if display none, return 0
            if($el.css("display") === "none") return 0;
            // if not in viewport, return 0

            const elH = $el.outerHeight(true);
            const H   = $(window).outerHeight(true);
            const r   = $el[0].getBoundingClientRect();
            const t= r.top;
            const b= r.bottom;

            return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
        }

        const debounce = (callback, timeout = 300) => {
            let timeoutId = null;
        
            return (...args) => {
                window.clearTimeout(timeoutId);
                timeoutId = window.setTimeout(() => {
                    callback(...args);
                }, timeout);
            };
        }

        const toggleFooterStickynessIfNeeded = () => {
            const isXL = _.isXl();
            const content = $(".idsa-main-content");

            // is page scroll enough so that the BOTTOM of the content is visible?
            const contentBottom = content.offset().top + content.height();
            const isContentBottomVisible = contentBottom < $(window).scrollTop() + $(window).height();
            const footer = $(".idsa-footer");
            const isFooterinViewport = isContentBottomVisible;
            footer.toggleClass("sticky", isXL && isFooterinViewport);
        };
        // on ready...
        $(document).ready(toggleFooterStickynessIfNeeded);
        // on scroll
        $(window).scroll((e)=>{
            toggleFooterStickynessIfNeeded();
        });
        // on resize
        $(window).resize(toggleFooterStickynessIfNeeded);
        // idsociety.org end
    };

    $(function () { init(); });
})();

