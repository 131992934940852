﻿class ShowMore extends HTMLElement {
    connectedCallback() {
        debugger;
        this.button = this.querySelector('.js-show-more-button');
        this.target = this.querySelector('.js-show-more-body');
        this.isVisible = false;

        this.button.addEventListener('click', () => {
            this.toggleVisibility();
        });

        this.updateButtonText();
    }

    toggleVisibility() {
        this.isVisible = !this.isVisible;
        this.updateTargetVisibility();
        this.updateButtonText();
    }

    updateTargetVisibility() {
        this.target.style.display = this.isVisible ? 'block' : 'none';
    }

    updateButtonText() {
        this.button.textContent = this.isVisible ? '- Less' : '+ More';
    }
}

export default ShowMore